import { keyframes, styled } from '../..';

const rotate = keyframes({
  '100%': { transform: 'rotate(360deg)' },
});

const prixClipFix = keyframes({
  '0%': { clipPath: 'polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)' },
  '25%': { clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)' },
  '50%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)',
  },
  '75%': {
    clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)',
  },
  '100%': { clipPath: 'polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)' },
});

// Styled components
export const LoaderWrapper = styled('div', {
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  position: 'relative',
  animation: `${rotate} 1s linear infinite`,
});

export const LoaderBefore = styled('div', {
  content: '',
  boxSizing: 'border-box',
  position: 'absolute',
  inset: 0,
  borderRadius: '50%',
  animation: `${prixClipFix} 2s linear infinite`,

  variants: {
    variant: {
      white: {
        border: '3px solid $pure',
      },
      blueWarmVivid70: {
        border: '3px solid $blueWarmVivid70',
      },
    },
  },
});
