import { createStitches } from '@stitches/react';

export const {
  config,
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
} = createStitches({
  media: {
    mqn1: '(min-width: 640px)',
    mqn2: '(min-width: 768px)',
    mqn3: '(min-width: 1024px)',
    mqx1: '(max-width: 640px)',
    mqx2: '(max-width: 768px)',
    mqx3: '(max-width: 1024px)',
  },
  theme: {
    colors: {
      blueWarmVivid90: '#071D41',
      blueWarmVivid70: '#1351B4',
      blueWarmVivid60: '#155BCB',
      blueWarmVivid20: '#5992ED',
      blueWarmVivid05: '#EDF5FF',
      greenCoolVivid05: '#E3F5E1',
      greenCoolVivid50: '#168821',

      pure: '#FFFFFF',
      gray05: '#F0F0F0',
      gray20: '#CCCCCC',
      gray40: '#888888',
      gray60: '#636363',
      gray90: '#1B1B1B',
      gray80: '#333333',
      redWarning01: '#FFF3F2',
      redWarning05: '#FFCDD2',
      redWarning50: '#E52207',
      redWarning70: '#E02800',
      white: 'white',
      pureBlack: '#000000',
      warning: '#FFA800',
      yello20: '#FFF5C2',
    },
    fontSizes: {
      lg: '2.25rem',
      md: '1.5rem',
      sm: '1rem',
      xs: '0.875rem',
    },
    fontWeights: {
      thin: 100,
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
    borderStyles: {
      solid: 'solid',
      dashed: 'dashed',
      dotted: 'dotted',
      double: 'double',
    },
    borderWidths: {
      thin: '1px',
      medium: '2px',
      thick: '4px',
    },
    radii: {
      none: '0px',
      small: '4px',
      medium: '8px',
      large: '16px',
      full: '9999px',
    },
    shadows: {},
  },
});

export type ThemeColors = typeof config.theme.colors;
export type ThemeSizes = typeof config.theme.fontSizes;
