import { styled } from '@/styles';

export const ButtonBased = styled('button', {
  width: '100%',
  minHeight: '3rem',
  display: 'flex',
  marginTop: '1.625rem',
  padding: '0.3rem 1.0rem 0.3rem 1.0rem',
  borderRadius: '35px',
  border: 0,
  background: '$blueWarmVivid70',
  color: '$pure',
  fontSize: '$sm',
  fontWeight: 600,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    variantType: {
      default: {},
      outline: {
        background: 'none',
        color: '$blueWarmVivid70',
        border: '0.0625rem solid $blueWarmVivid70',
      },
      link: {
        margin: '0',
        background: 'none',
        textDecorationLine: 'underline',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        border: 'none',
        color: '$blueWarmVivid70',
        padding: '0px!important',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
    },
  },
  '&:disabled': {
    opacity: '0.5',
    cursor: 'not-allowed',
  },
});
