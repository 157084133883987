import { LoaderBefore, LoaderWrapper } from '@/styles/components/shared/loader';

export default function Loader({
  variant,
}: {
  variant: 'white' | 'blueWarmVivid70';
}) {
  return (
    <LoaderWrapper>
      <LoaderBefore variant={variant} />
    </LoaderWrapper>
  );
}
