'use client';
import * as Stitches from '@stitches/react';
import { ButtonHTMLAttributes } from 'react';
import { ButtonBased } from './style';

interface ButtonTyping
  extends Stitches.VariantProps<typeof ButtonBased>,
    ButtonHTMLAttributes<HTMLButtonElement> {}

export default function Button({ children, ...props }: ButtonTyping) {
  return <ButtonBased {...props}>{children}</ButtonBased>;
}
